<template>
    <v-responsive :aspect-ratio="1 / 1" class="u-btn-custom-line">
        <svg viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg" class="w-100 h-100">
            <rect x="0.5" y="0.5" width="139" height="139" rx="69.5" stroke="url(#paint0_linear_711_5847)"/>
            <defs>
                <linearGradient id="paint0_linear_711_5847" x1="70" y1="0" x2="70" y2="140" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#B18A00"/>
                    <stop offset="0.500513" stop-color="#FFEA9F"/>
                    <stop offset="1" stop-color="#B18A00"/>
                </linearGradient>
            </defs>
        </svg>
    </v-responsive>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.u-btn-custom-line{
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 1;
    visibility: visible;
}
</style>