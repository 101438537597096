import axios from "@/plugins/axios";

let url = "/api/console/lawyers";

export default {
    get(data){
        return axios.get(url, data).then(result => result.data);
    },
    put(data){
        return axios.put(url, data).then(result => result.data);
    },

    postThumb(data, image) {
        var formData = new FormData();
        formData.append("image", image);
        var headers = { "Content-Type": "multipart/form-data" };
        return axios.post(`${url}/${data._id}/image`, formData, { headers });
    },
}
