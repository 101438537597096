var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-tabs', _vm._b({
    staticClass: "v-tabs--inline",
    scopedSlots: _vm._u([_vm._l(_vm.$slots, function (_, slotName) {
      return {
        key: slotName,
        fn: function () {
          return [_vm._t(slotName)];
        },
        proxy: true
      };
    })], null, true),
    model: {
      value: _vm.tabs,
      callback: function ($$v) {
        _vm.tabs = $$v;
      },
      expression: "tabs"
    }
  }, 'v-tabs', Object.assign({}, _vm.attrs_tabs_common, _vm.$attrs), false));

}
var staticRenderFns = []

export { render, staticRenderFns }