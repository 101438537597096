var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-responsive', {
    staticClass: "u-btn-custom-line",
    attrs: {
      "aspect-ratio": 1 / 1
    }
  }, [_c('svg', {
    staticClass: "w-100 h-100",
    attrs: {
      "viewBox": "0 0 140 140",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('rect', {
    attrs: {
      "x": "0.5",
      "y": "0.5",
      "width": "139",
      "height": "139",
      "rx": "69.5",
      "stroke": "url(#paint0_linear_711_5847)"
    }
  }), _c('defs', [_c('linearGradient', {
    attrs: {
      "id": "paint0_linear_711_5847",
      "x1": "70",
      "y1": "0",
      "x2": "70",
      "y2": "140",
      "gradientUnits": "userSpaceOnUse"
    }
  }, [_c('stop', {
    attrs: {
      "stop-color": "#B18A00"
    }
  }), _c('stop', {
    attrs: {
      "offset": "0.500513",
      "stop-color": "#FFEA9F"
    }
  }), _c('stop', {
    attrs: {
      "offset": "1",
      "stop-color": "#B18A00"
    }
  })], 1)], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }