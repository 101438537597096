var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('u-tit-wrap-default', _vm._b({
    class: _vm.titFit ? 'tit-fit' : ''
  }, 'u-tit-wrap-default', _vm.$attrs, false), [this.$slots['TitAdd'] ? _c('u-tit-subtit', {
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "200"
    }
  }, [_vm._t("TitAdd")], 2) : _vm._e(), _c('u-tit-default', {
    staticClass: "tit--xl",
    class: _vm.dark ? 'white--text' : '',
    attrs: {
      "data-aos": "fade-up"
    }
  }, [_vm._t("default")], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }