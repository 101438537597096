var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    staticClass: "icon-kakao",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 22 22"
    }
  }, [_c('rect', {
    staticClass: "cls-2",
    staticStyle: {
      "fill": "none"
    },
    attrs: {
      "width": "22",
      "height": "22"
    }
  }), _c('path', {
    staticClass: "cls-1",
    staticStyle: {
      "fill": "#fff"
    },
    attrs: {
      "d": "m7.12,12.24c.11.05.23.07.34.07.23,0,.4-.09.45-.24l.27-.7h1.65l.27.7c.05.15.23.24.45.24.12,0,.24-.03.34-.07.15-.07.29-.26.13-.77l-1.29-3.41c-.11-.31-.4-.52-.72-.54-.33.02-.61.23-.72.53l-1.3,3.41c-.16.51-.02.7.13.77Zm1.89-3.36l.54,1.54h-1.08l.54-1.54Z"
    }
  }), _c('path', {
    staticClass: "cls-1",
    staticStyle: {
      "fill": "#fff"
    },
    attrs: {
      "d": "m11.9,12.24h1.74s.04,0,.06,0c.27-.02.48-.25.47-.53-.02-.27-.25-.48-.53-.47h-1.15v-3.18c-.01-.29-.24-.52-.53-.53-.31-.01-.56.23-.58.53v3.68c0,.28.24.5.52.5Z"
    }
  }), _c('path', {
    staticClass: "cls-1",
    staticStyle: {
      "fill": "#fff"
    },
    attrs: {
      "d": "m14.91,12.32c.3,0,.54-.24.54-.54v-1.18l.19-.19,1.27,1.68c.09.12.22.19.36.21.3.04.57-.17.61-.47h0c.02-.14-.02-.28-.1-.4l-1.33-1.77,1.24-1.23c.08-.09.13-.21.12-.33-.02-.28-.25-.5-.53-.5-.11,0-.22.04-.3.12l-1.51,1.51v-1.16c0-.3-.24-.54-.54-.54s-.54.24-.54.54v3.7c0,.3.24.54.54.54Z"
    }
  }), _c('path', {
    staticClass: "cls-1",
    staticStyle: {
      "fill": "#fff"
    },
    attrs: {
      "d": "m4.43,8.59c.23,0,.58,0,.83,0,.01,1.05-.01,2.15,0,3.18,0,.36.24.47.53.53.03,0,.06,0,.09,0,.26-.09.45-.19.45-.52.02-1.03,0-2.13,0-3.18.25,0,.6,0,.83,0,.32-.02.54-.22.53-.53s-.17-.52-.53-.53c-.85-.01-1.9-.01-2.74,0-.36,0-.52.22-.53.53s.21.51.53.53Z"
    }
  }), _c('path', {
    staticClass: "cls-1",
    staticStyle: {
      "fill": "#fff"
    },
    attrs: {
      "d": "m5.27,20.28c-.14,0-.28-.03-.41-.1-.36-.21-.44-.58-.41-.83.01-.09.04-.25.84-3.02-2.69-1.5-4.29-3.93-4.29-6.58C1,5.32,5.49,1.72,11,1.72s10,3.6,10,8.01-4.49,8.01-10,8.01c-.5-.02-1.01-.03-1.51-.09-3.31,2.31-3.78,2.54-3.99,2.58-.08.02-.16.03-.24.03ZM11,2.95c-4.84,0-8.78,3.05-8.78,6.79,0,2.33,1.52,4.47,4.06,5.73l.46.23-.14.49c-.35,1.19-.56,1.96-.7,2.46.53-.34,1.43-.95,2.99-2.04l.3-.21.24.03c.52.07,1.07.11,1.58.11,4.84,0,8.78-3.05,8.78-6.79s-3.94-6.79-8.78-6.79Z"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }