<template>
    <v-btn v-bind="$attrs" color="primary" outlined icon class="u-btn-custom" @click="$emit('click')">
        <icon-rounded-object gradient/>
        <div class="u-btn-custom__inner">
            <u-btn-custom-line />
            <div class="u-btn-custom__title font-size-14 font-size-md-16 font-weight-bold font-secondary">{{title}}</div>
            <icon-arrow-primary class="mt-4px mt-md-8px"/>
        </div>
    </v-btn>
</template>

<script>
import UBtnCustomLine from "@/components/publish/styles/buttons/u-btn-custom-line.vue";
import IconRoundedObject from "@/components/publish/styles/icons/icon-rounded-object.vue";
import IconArrowPrimary from "@/components/publish/parents/icons/icon-arrow-primary.vue";
export default {
    props: {
        title: { type: String, default: "View More" },
    },
    components: {
        UBtnCustomLine,
        IconRoundedObject,
        IconArrowPrimary,
    },
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.u-btn-custom{
    &.v-btn.v-btn--icon.v-size--default.v-btn--outlined{
        position: relative;
        border-color: transparent !important;
        width: 100px !important;
        height: 100px !important;
        ::v-deep{
            .v-btn__content{
                width: 100%;
                height: 100%;
                padding: 10px;
            }
        }
        &::before{
            display: none;
        }
    }
    &__inner{
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
    }
    &__title{
        background: linear-gradient(180deg, #B18A00 0%, #FFEA9F 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
    .u-btn-custom{
        &.v-btn.v-btn--icon.v-size--default.v-btn--outlined{
            width: 180px !important;
            height: 180px !important;
            ::v-deep{
                .v-btn__content{
                    padding: 20px;
                }
            }
        }
    }
}
@media (min-width:1024px){
    .u-btn-custom:hover{
        .u-btn-custom__inner{
            color: var(--v-grey-darken3) !important;
            background: linear-gradient(135deg, #B18A00 0%, #FFEA9F 52.08%, #B18A00 100%);
            .u-btn-custom__title{
                background: transparent;
                -webkit-text-fill-color: var(--v-grey-darken3) !important;
            }
            .u-btn-custom-line{
                opacity: 0;
                visibility: hidden;
            }
        }
    }
}
@media (min-width:1200px){
}

</style>
