var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-btn', _vm._b({
    staticClass: "u-btn-custom",
    attrs: {
      "color": "primary",
      "outlined": "",
      "icon": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('click');
      }
    }
  }, 'v-btn', _vm.$attrs, false), [_c('icon-rounded-object', {
    attrs: {
      "gradient": ""
    }
  }), _c('div', {
    staticClass: "u-btn-custom__inner"
  }, [_c('u-btn-custom-line'), _c('div', {
    staticClass: "u-btn-custom__title font-size-14 font-size-md-16 font-weight-bold font-secondary"
  }, [_vm._v(_vm._s(_vm.title))]), _c('icon-arrow-primary', {
    staticClass: "mt-4px mt-md-8px"
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }