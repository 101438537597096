var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.direction == 'left' ? _c('svg', _vm._b({
    staticClass: "icon-svg icon-arrow-custom",
    class: [_vm.color ? _vm.color : '', _vm.size ? 'icon--size-' + _vm.size : '', _vm.direction ? 'icon--direction-' + _vm.direction : ''],
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 19 6"
    }
  }, 'svg', _vm.$attrs, false), [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M6.60527 0L0.702032 5.00041C0.512056 5.16133 0.448988 5.41266 0.543133 5.63362C0.637278 5.85458 0.869389 6 1.12793 6H18.5V4.8476H2.73389L7.45707 0.846785L6.60527 0Z"
    }
  })]) : _vm.direction == 'right' ? _c('svg', _vm._b({
    staticClass: "icon-svg icon-arrow-custom",
    class: [_vm.color ? _vm.color : '', _vm.size ? 'icon--size-' + _vm.size : '', _vm.direction ? 'icon--direction-' + _vm.direction : ''],
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 19 6"
    }
  }, 'svg', _vm.$attrs, false), [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M12.3947 0L18.298 5.00041C18.4879 5.16133 18.551 5.41266 18.4569 5.63362C18.3627 5.85458 18.1306 6 17.8721 6H0.5V4.8476H16.2661L11.5429 0.846785L12.3947 0Z"
    }
  })]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }