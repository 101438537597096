<template>
    <tit v-bind="$attrs" class="tit--subtit primary--text"><slot /></tit>
</template>

<script>
import Tit from "@/components/publish/styles/typography/tit.vue";

export default {
    props: {},
    components: {
        Tit,
    },
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.tit {
    // subtit
    &--subtit{
        font-size: var(--tit-font-size-sm);
        margin-bottom: 12px;
        font-family: var(--font-secondary) !important;
        line-height: 1;
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
    .tit {
        // subtit
        &--subtit{
            margin-bottom: 24px;
        }
    }
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}
@media (min-width:1200px){
}

</style>