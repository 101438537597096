<template>
    <div v-if="className == 'sub-tab'" :class="className">
        <v-container>
            <template v-for="item in gnbs">
                <tabs-primary v-if="item.title == sh" :key="item.title" hide-slider>
                    <tab-primary v-for="child in item.children" :key="child.title" :to="child.path" :class="{ 'v-tab--active': tabActive == child.title }">{{ child.title }}</tab-primary>
                </tabs-primary>
            </template>
        </v-container>
    </div>
    <ul v-else-if="className == 'gnb'" :class="className">
        <template v-for="item in gnbs">
            <li v-if="!item.children" :class="className+'__li'" :key="item.title">
                <a :href="item.path" target="_blank" :class="className+'__link'"><h2>{{item.title}}</h2></a>
            </li>

            <li v-else :class="className+'__li'" :key="item.title">
                <div :class="className+'__link gnb__link--toggle d-xl-none'"><h2>{{item.title}}</h2></div>
                <router-link :to="item.path" :class="className+'__link gnb__link--toggle d-none d-xl-flex'"><h2>{{item.title}}</h2></router-link>
                <ul :class="className+'__sub'">
                    <li v-for="child in item.children" :key="child.title">
                        <router-link :to="child.path">{{child.title}}</router-link>
                    </li>
                </ul>
            </li>
        </template>
    </ul>
    <ul v-else-if="className == 'slide-gnb'" :class="className">
        <template v-for="(item, index) in gnbs">
            <li :class="className+'__li'" :key="index">
                <router-link :to="item.path" :class="className+'__link'">                    
                    <div :class="className+'__num'">
                        <v-img :src="'/images/common/0'+(index+1)+'.png'" :class="className+'__num-no-active'" height="32" width="46" contain />
                        <v-img :src="'/images/common/0'+(index+1)+'-active.png'" :class="className+'__num-active'" height="32" width="46" contain />
                    </div>
                    <h2>{{item.title}}</h2>
                </router-link>
            </li>
        </template>
    </ul>
</template>

<script>
import TabsPrimary from "@/components/publish/parents/tabs/tabs-primary.vue";
import TabPrimary from "@/components/publish/parents/tabs/tab-primary.vue";
export default {
    props: {
        className : String,
        indexNum : String,
        sh: String,
        tabActive: String,
    },
    components: {
        TabsPrimary,
        TabPrimary,
    },
    data: function(){
        return {
            window: {
                width: 0,
                height: 0,
            },
            gnbs : [
                {
                    title : "오시는길",
                    path : "/",
                },
                {
                    title : "상담신청",
                    path : "https://l2y36.channel.io/home",
                },
                {
                    title : "1661-8731 전화 상담신청",
                    path : "tel:1661871",
                },
            ],
        };
    },
    computed: {
        isDesktop: function() {
            return this.window.width >= 1200;
        },
        isMobile: function() {
            return this.window.width < 1200;
        },
    },
    created: function(){
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
    mounted: function(){
    },
    methods: {
        handleResize: function() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
        },
    }
};
</script>