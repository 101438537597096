var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('footer', {
    staticClass: "footer"
  }, [_c('div', {
    staticClass: "footer__inner pt-60px pt-md-90px pt-lg-120px pb-40px pb-md-60px pb-lg-80px"
  }, [_c('v-container', [_c('tit', {
    staticClass: "white--text mb-60px mb-md-90px mb-lg-120px"
  }, [_vm._v(" 마음까지 살필 수 있는 "), _c('br'), _vm._v(" 든든한 동반자가 되어드리겠습니다. ")]), _c('v-row', {
    staticClass: "my-n2px my-md-n8px",
    staticStyle: {
      "max-width": "1300px"
    }
  }, _vm._l(_vm.info, function (item) {
    return _c('v-col', {
      key: item.title,
      staticClass: "py-2px py-md-8px",
      attrs: {
        "cols": "auto"
      }
    }, [_c('txt', {
      staticClass: "txt--xs txt--light"
    }, [_c('span', {
      staticClass: "white--text"
    }, [_vm._v(_vm._s(item.title))]), _vm._v(" " + _vm._s(item.info) + " ")])], 1);
  }), 1), _c('v-divider', {
    staticClass: "my-40px my-md-60px my-lg-80px",
    staticStyle: {
      "border-color": "rgba(255, 255, 255, 0.2)"
    }
  }), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "auto",
      "order-sm": "2"
    }
  }, [_c('v-img', {
    staticClass: "w-140px w-md-180px",
    attrs: {
      "src": "/images/logo-light.svg",
      "contain": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "",
      "order-sm": "1"
    }
  }, [_c('txt', {
    staticClass: "txt--xs txt--light"
  }, [_vm._v("Copyright 2023. TAESUNG All Rights Reserved.")])], 1)], 1)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }