var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-btn', _vm._b({
    staticClass: "btn-top-primary v-btn--text-fit",
    attrs: {
      "fixed": "",
      "text": "",
      "color": "white"
    },
    on: {
      "click": function ($event) {
        return _vm.$vuetify.goTo(_vm.target, _vm.options);
      }
    }
  }, 'v-btn', Object.assign({
    color: _vm.color
  }, _vm.$attrs), false), [_c('span', {
    staticClass: "btn-top-primary__line"
  }), _c('div', {
    staticClass: "font-size-14 font-size-md-18 mt-8px mt-md-16px line-height-1 font-secondary"
  }, [_vm._v("TOP")])]);

}
var staticRenderFns = []

export { render, staticRenderFns }