var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "main-visual"
  }, [_c('div', {
    staticClass: "main-visual__bg"
  }), _c('v-sheet', {
    staticClass: "main-visual__inner text-center pt-80px pt-xl-130px tertiary--text line-height-1",
    attrs: {
      "color": "transparent"
    }
  }, [_c('tit', {
    staticClass: "tit--lg",
    attrs: {
      "data-aos": "fade-up"
    }
  }, [_vm._v("집의 안팎을 살핍니다")]), _c('div', {
    staticClass: "font-size-2 font-size-md-28 font-weight-medium text-uppercase mt-20px mt-md-30px",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "200"
    }
  }, [_vm._v("caring for you both inside and outside your home")]), _c('div', {
    staticClass: "font-size-2 font-size-md-28 mt-10px mt-md-16px",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "300"
    }
  }, [_vm._v("아파트 하자 · 단체등기 · 재개발 재건축 · 이혼")]), _c('txt', {
    staticClass: "txt--lg txt--dark mt-20px mt-md-30px",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "400"
    }
  }, [_vm._v("법무법인 태성은 건설과 이혼 분야에 특화된 로펌입니다.")])], 1), _c('u-scroll-ani')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }