var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('swiper', {
    ref: "swiperMagazine",
    staticClass: "swiper swiper--magazine magazine-slide",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "100",
      "options": _vm.swiperOptionsMagazine
    }
  }, _vm._l(_vm.boards, function (board) {
    return _c('swiper-slide', {
      key: board._id
    }, [_c('v-card', {
      staticClass: "magazine-card transparent",
      attrs: {
        "rounded": "sm",
        "href": board.url,
        "target": "_blank"
      }
    }, [_c('v-responsive', {
      staticClass: "image-card",
      style: `background-image: url(${board === null || board === void 0 ? void 0 : board.thumb})`,
      attrs: {
        "max-width": "446",
        "aspect-ratio": 1 / 1
      }
    }), _c('div', {
      staticClass: "magazine-card__contents pa-16px pa-md-20px pa-lg-40px pb-2px pb-md-6px pb-lg-26px"
    }, [_c('div', {
      staticClass: "w-100"
    }, [_c('tit', {
      staticClass: "white--text tit--sm text-truncate"
    }, [_vm._v(_vm._s(board === null || board === void 0 ? void 0 : board.subject))]), _c('txt', {
      staticClass: "white--text text-truncate mt-4px mt-md-8px"
    }, [_vm._v(_vm._s(board === null || board === void 0 ? void 0 : board.summary))]), _c('btn-primary', {
      staticClass: "v-btn--none-active-bg mt-md-16px"
    }, [_c('span', {
      staticClass: "v-btn__text"
    }, [_vm._v("View More")])])], 1)])], 1)], 1);
  }), 1);

}
var staticRenderFns = []

export { render, staticRenderFns }