var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.next ? _c('u-btn-text-fit', _vm._b({
    staticClass: "font-secondary line-height-1 font-weight-bold",
    on: {
      "click": function ($event) {
        return _vm.$emit('click');
      }
    }
  }, 'u-btn-text-fit', _vm.$attrs, false), [_vm._v(" next "), _c('icon-arrow-primary', {
    staticClass: "ml-6px ml-md-8px"
  })], 1) : _vm.prev ? _c('u-btn-text-fit', _vm._b({
    staticClass: "font-secondary line-height-1 font-weight-bold",
    on: {
      "click": function ($event) {
        return _vm.$emit('click');
      }
    }
  }, 'u-btn-text-fit', _vm.$attrs, false), [_c('icon-arrow-primary', {
    staticClass: "mr-6px mr-md-8px",
    attrs: {
      "direction": "left"
    }
  }), _vm._v(" prev ")], 1) : _c('v-btn', {
    staticClass: "v-btn--text-fit",
    attrs: {
      "icon": "",
      "text": ""
    }
  }, [_c('icon-arrow-primary', _vm._b({}, 'icon-arrow-primary', {
    direction: _vm.direction
  }, false))], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }