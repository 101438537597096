export const attrs_input = {
    dense: true,
    outlined: true,
    hideDetails: true,
    persistentPlaceholder: true,
};

export const attrs_table = {
    "disable-sort": true,
    "disable-pagination": true,
    "hide-default-footer": true,
};

//  Client attrs
export const attrs_tabs_common = {
    "grow": true,
    "hide-slider": true,
    "show-arrows": true,
};
export const attrs_controls_common = {
    "hide-details": "auto",
};
export const attrs_input_common = {
    "hide-details": "auto",
};