import axios from "@/plugins/axios";

let url = "/api/v1/forms";

export default {
    post(_form, file) {
        var formData = new FormData();
        formData.append("file", file);
        var headers = { "Content-Type": "multipart/formdata" };
        return axios.post(`${url}/${_form}/file`, formData, { headers }).then((result) => result.data);
    },
    delete(_form, index) {
        return axios.delete(`${url}/${_form}/file`).then((result) => result.data);
    },
};
