var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-btn', _vm._b({
    staticClass: "v-btn--text-fit font-weight-bold font-secondary line-height-1",
    attrs: {
      "text": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('click');
      }
    }
  }, 'v-btn', Object.assign({
    color: _vm.color
  }, _vm.$attrs), false), _vm._l(_vm.$slots, function (item, slotName, index) {
    return _c('span', {
      key: index
    }, [_vm._t(slotName), _c('icon-arrow-primary', {
      staticClass: "ml-6px ml-md-8px"
    })], 2);
  }), 0);

}
var staticRenderFns = []

export { render, staticRenderFns }