var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    staticClass: "icon-tel",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 22 22"
    }
  }, [_c('rect', {
    staticClass: "cls-2",
    staticStyle: {
      "fill": "none"
    },
    attrs: {
      "width": "22",
      "height": "22"
    }
  }), _c('path', {
    staticClass: "cls-1",
    staticStyle: {
      "fill": "#fff"
    },
    attrs: {
      "d": "m20.49,15.75l-2.49-2.49c-.69-.69-1.8-.69-2.49,0l-1.13,1.13c-.52.52-1.36.52-1.88,0l-4.9-4.9c-.52-.52-.52-1.36,0-1.88l1.13-1.13c.68-.68.69-1.79,0-2.49l-2.49-2.48c-.69-.69-1.8-.69-2.48,0l-.91.9c-2.47,2.47-2.47,6.49,0,8.97l7.76,7.77c2.48,2.48,6.49,2.48,8.97,0l.9-.9c.69-.69.69-1.8,0-2.49ZM4.59,2.34c.23-.23.6-.23.83,0l2.49,2.48c.23.23.23.6,0,.83l-.41.41-3.31-3.31.41-.41Zm13.78,16.32c-2.03,1.66-5,1.56-6.92-.35l-7.76-7.77c-1.9-1.9-2.02-4.88-.35-6.92,0-.01.02-.02.03-.04l3.3,3.3c-.87.98-.83,2.48.11,3.42l4.9,4.9c.94.94,2.44.98,3.42.11l3.3,3.3s-.02.02-.04.03Zm1.29-1.26l-.41.41-3.32-3.32.41-.41c.23-.23.6-.23.83,0l2.49,2.49c.23.23.23.6,0,.83Z"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }