var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('u-card-border-point', _vm._b({}, 'u-card-border-point', _vm.$attrs, false), [_c('txt', {
    staticClass: "white--text",
    scopedSlots: _vm._u([_vm._l(_vm.$slots, function (_, slotName) {
      return {
        key: slotName,
        fn: function () {
          return [_vm._t(slotName)];
        },
        proxy: true
      };
    })], null, true)
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }