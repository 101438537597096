<template>
    <v-card v-bind="$attrs" outlined rounded="xs" color="rgba(255, 255, 255, .2)" class="card-border-point">
        <div class="px-16px px-md-24px py-12px py-md-16px">
            <slot />          
        </div>
    </v-card>
</template>

<script>
export default {
    components: {
    },
}
</script>

<style lang="scss" scoped>
.card-border-point{
    position: relative;
    &::after{
        content: "";
        display: block;
        position: absolute;
        width: 2px;
        height: 100%;
        left: 0;
        top: 0;
        background: linear-gradient(180deg, #B18A00 0%, #FFEA9F 50%, #B18A00 100%);
    }
}
.cards{
    .card-border-point:not(:last-child){
        margin-bottom: 12px;
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
    .cards{
        .card-border-point:not(:last-child){
            margin-bottom: 24px;
        }
    }
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}

</style>