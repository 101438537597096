<template>
    <v-btn v-bind="{ color, ...$attrs }" fixed text color="white" class="btn-top-primary v-btn--text-fit" @click="$vuetify.goTo(target, options)">
        <span class="btn-top-primary__line"></span>
        <div class="font-size-14 font-size-md-18 mt-8px mt-md-16px line-height-1 font-secondary">TOP</div>
    </v-btn>
</template>

<script>
import IconArrowPrimary from "@/components/publish/parents/icons/icon-arrow-primary.vue";

export default {
    props: {
        color: { type: String, default: "grey" },
    },
    components: {
        IconArrowPrimary,
    },
    data: () => {
        return {
            type: "number",
            number: 0,
            duration: 500,
            offset: 0,
            easing: "easeInOutCubic",
        };
    },
    computed: {
        target: function () {
            const value = this[this.type];
            if (!isNaN(value)) return Number(value);
            else return value;
        },
        options: function () {
            return {
                duration: this.duration,
                offset: this.offset,
                easing: this.easing,
            };
        },
    },
};
</script>

<style lang="scss" scoped>
// btn-top-primary
.btn-top-primary {
    bottom: var(--btn-top-position-bottom) !important;
    right: var(--btn-top-position-right) !important;
    transition: all ease-out 0.2s;
    box-shadow: none !important;
    will-change: unset !important;
    z-index: 10;
    ::v-deep{
        .v-btn__content{
            flex-direction: column;
        }
    }
    &__line{
        width: 1px;
        height: 20px;
        background-color: rgba(255, 255, 255, 0.6);
        display: block;
        margin: 0 auto;
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
    .btn-top-primary {
        z-index: 101;
        &__line{
            height: 40px;
        }
    }
}
</style>
