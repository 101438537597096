<template>
    <h1 class="header__logo">
        <a href="/" title="홈으로 이동"><span class="d-sr-only">법무법인 태성</span></a>
    </h1>
</template>

<script>
export default {

}
</script>

<style>

</style>