<template>
    <u-btn-text-fit v-if="next" v-bind="$attrs" class="font-secondary line-height-1 font-weight-bold" @click="$emit('click')">
        next
        <icon-arrow-primary class="ml-6px ml-md-8px"/>
    </u-btn-text-fit>
    <u-btn-text-fit v-else-if="prev" v-bind="$attrs" class="font-secondary line-height-1 font-weight-bold" @click="$emit('click')">        
        <icon-arrow-primary direction="left" class="mr-6px mr-md-8px" />
        prev
    </u-btn-text-fit>
    <v-btn v-else icon text class="v-btn--text-fit">
        <icon-arrow-primary v-bind="{ direction }" />
    </v-btn>
</template>

<script>
import IconArrowPrimary from "@/components/publish/parents/icons/icon-arrow-primary.vue";
import UBtnTextFit from "@/components/publish/styles/buttons/u-btn-text-fit.vue";

export default {
    props: {
        direction: { type: String, default: "left" },
        prev: { type: Boolean, default: false },
        next: { type: Boolean, default: false },
    },
    components: {
        IconArrowPrimary,
        UBtnTextFit,
    },
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .v-btn.grey--text:hover{
        color: var(--v-primary-base) !important;
    }
}
@media (min-width:1200px){
}
</style>
