<template>
    <div v-bind="$attrs" class="txt"><slot /></div>
</template>

<script>
export default {
    props: {
        txtSize: { type: String, default: "" },
        txtColor: { type: String, default: "" },
    },
    components: {},
    data: () => {
        return {};
    },
    mounted() {},
    computed: {},
};
</script>

<style lang="scss" scoped>
.txt {
    word-break: keep-all;
    font-size: var(--txt-font-size);
    font-weight: var(--txt-font-weight);
    line-height: var(--txt-line-height);
    color: var(--txt-color);
    // size
    &--xs {
        font-size: var(--txt-font-size-xs);
    }
    &--sm {
        font-size: var(--txt-font-size-sm);
    }
    &--lg {
        font-size: var(--txt-font-size-lg);
    }
    &--xl {
        font-size: var(--txt-font-size-xl);
    }
    // color
    &--light {
        color: var(--txt-color-light);
    }
    &--dark {
        color: var(--txt-color-dark);
    }

    p{
        margin-top: 6px;
        &:first-child {
            margin-top: 0;
        }
    }
}
.tit-wrap + .txt {
    margin-top: 16px;
    // size
    &--xs {
        margin-top: 8px;
    }
    &--sm {
        margin-top: 14px;
    }
    &--lg {
        margin-top: 20px;
    }
    &--xl {
        margin-top: 24px;
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .txt {
        p {
            margin-top: 8px;
        }
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
</style>
