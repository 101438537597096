<template>
    <v-app>
        <!-- S: HEADER -->
        <main-header />
        <!-- E: HEADER -->

        <!-- S: CONTENT -->
        <v-main id="main">
            <main-pc-logo class="d-none d-xl-block"/>
            <slot name="subHead" />
            <slot name="contentsImmersive" />
            <div id="contents">
                <slot />
            </div>
        </v-main>
        <!-- E: CONTENT -->

        <!-- S: FOOTER -->
        <main-footer />
        <!-- E: FOOTER -->

        <btn-top-primary />
    </v-app>
</template>

<script>
import MainHeader from "@/components/client/inc/main-header.vue";
import MainPcLogo from "@/components/client/inc/main-pc-logo.vue";
import MainFooter from "@/components/client/inc/main-footer.vue";
import BtnTopPrimary from '@/components/publish/parents/buttons/btn-top-primary.vue';
export default {
    props: {
        isMain: { type: Boolean, default: true },
    },
    components: {
        MainHeader,
        MainPcLogo,
        MainFooter,
        BtnTopPrimary,
    },
    mounted() {
        window.addEventListener("copy", this.preventCopy);
        window.addEventListener("contextmenu", this.preventCopy);
        window.addEventListener("selectstart", this.preventDefault);
        window.addEventListener("dragstart", this.preventDefault);
        this.init();

        // 전체메뉴 초기화
        var HTML = document.querySelector('html');
        var header = document.querySelector('.header');
        header.classList.remove('pc-menu-on');
        HTML.classList.remove('pc-menu-hidden');
    },
    destroyed() {
        window.removeEventListener("copy", this.preventCopy);
        window.removeEventListener("contextmenu", this.preventCopy);
        window.removeEventListener("selectstart", this.preventDefault);
        window.removeEventListener("dragstart", this.preventDefault);
    },
	methods : {
		init : function() {
            let mobileWidth = 768;
            let offsetY = 300;
            if (window.innerWidth < mobileWidth) {
                offsetY = 180;
            }
            setTimeout(() => {
                AOS.init({
                    once: true,
                    offset: offsetY,
                    duration: 500,
                    throttleDelay : 100,
                    easing : 'ease-out',
                    anchorPlacement : 'top-center',
                });
            }, 100);
		}
	},
};
</script>
