<template>
    <client-page class="app--main">

        <main-visual />

        <main-gate />

        <main-section class="main-section--introduction">
            <main-introduction />
        </main-section>

        <main-section class="main-section--magazine">
            <template #mainSectionImmersive>
                <v-img src="/images/main/magazine-bg2.svg" aspect-ratio="1840 / 480" class="magazine-bg magazine-bg--1 w-100" />
            </template>
            <v-row>
                <v-col cols="12" md="4">
                    <tit-wrap-primary dark>
                        매거진
                        <template #TitAdd>Magazine</template>
                    </tit-wrap-primary>
                </v-col>
                <v-col cols="12" md="8" class="mt-n20px mt-md-0">
                    <main-magazine />
                </v-col>
            </v-row>
        </main-section>

        <main-section class="main-section--telinfo">
            <v-row>
                <v-col cols="12" md="4">
                    <tit-wrap-primary dark>
                        태성 대표번호
                        <template #TitAdd>Contact Us</template>
                    </tit-wrap-primary>
                </v-col>
                <v-col cols="12" md="8" class="mt-n20px mt-md-0">
                    <div class="pl-md-16px pl-lg-32px">
                        <main-telinfo />
                    </div>
                </v-col>
            </v-row>
        </main-section>

        <main-section class="main-section--contact">            
            <tit-wrap-primary dark class="text-center">
                오시는 길
                <template #TitAdd>Contact Us</template>
            </tit-wrap-primary>
            <main-contact />
        </main-section>

        <popup-layer></popup-layer>
    </client-page>
</template>

<script>
import ClientPage from "@/sets/styles/apps/client-page.vue";
import MainSection from "@/sets/styles/mains/main-section.vue";
import MainVisual from "@/components/client/main/main-visual.vue";
import MainGate from "@/components/client/main/main-gate.vue";
import MainIntroduction from "@/components/client/main/main-introduction.vue";
import MainMagazine from "@/components/client/main/main-magazine.vue";
import MainTelinfo from "@/components/client/main/main-telinfo.vue";
import MainContact from "@/components/client/main/main-contact.vue";
import PopupLayer from "@/components/client/popup/popup-layer.vue";

import TitWrapPrimary from "@/components/publish/parents/typography/tit-wrap-primary.vue";
import UTitWrapDefault from "@/components/publish/styles/typography/u-tit-wrap-default.vue";
import BtnPrimary from "@/components/publish/parents/buttons/btn-primary.vue";
import BtnSecondary from "@/components/publish/parents/buttons/btn-secondary.vue";

export default {
    components: {
        ClientPage,
        MainSection,
        MainVisual,
        MainGate,
        MainIntroduction,
        MainMagazine,
        MainTelinfo,
        MainContact,
        PopupLayer,

        TitWrapPrimary,
        UTitWrapDefault,
        BtnPrimary,
        BtnSecondary,
    },
    props: {},
    data() {
        return {};
    },

};
</script>

<style lang="scss" scoped>
.main-section{
    &--business{
        background-image: url(/images/main/business-bg.jpg);
        .business-bg{
            position: absolute;
            left: 0;
            &--1{
                top: 0;
            }
            &--2{
                top: 50%;
                transform: translateY(-50%);
            }
            &--3{
                bottom: 0;
            }
        }
    }
    &--introduction{
        background-image: url(/images/main/introduction-bg.jpg);
    }
    &--magazine{
        background-image: url(/images/main/magazine-bg.jpg);
        .magazine-bg{
            position: absolute;
            left: 0;
            &--1{
                bottom: 0;
            }
        }
    }
    &--telinfo{
        background-image: url(/images/main/telinfo-bg.jpg);
    }
    &--contact{
        overflow: hidden;
        background-image: url(/images/main/contact-bg.jpg);
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}
</style>
