<template>
    <icon-arrow-custom v-bind="$attrs" />
</template>

<script>
import IconArrowCustom from "@/components/publish/styles/icons/icon-arrow-custom.vue";
export default {
    props: {},
    components: {
        IconArrowCustom,
    },
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
