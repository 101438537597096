<template>
    <!-- <v-select v-model="$i18n.locale" :items="items" item-text="text" item-value="value" @input="input"></v-select> -->
    <div class="lang-wrap">
        <v-btn icon text><v-icon>mdi-earth</v-icon></v-btn>
        <ul class="lang">
            <li class="on">KOR</li>
            <li><router-link to="/en">ENG</router-link></li>
        </ul>
    </div>
</template>
<script>
// import axios from "@/plugins/axios";

export default {
    // data(){
    //     return {
    //         items: [
    //             { text: "KO", value: "ko" },
    //             { text: "EN", value: "en" },
    //             { text: "CN", value: "cn" }
    //         ]
    //     }
    // },
    // methods: {
    //     input(locale){
    //         axios.defaults.headers.common['Accept-Language'] = locale;
    //     }
    // },
    mounted() {
        // lang
        var lang = document.querySelector('.lang-wrap');
        lang.addEventListener('click', langBox);
    },
}

function langBox() {
    var lang = document.querySelector('.lang-wrap');
    lang.classList.toggle('active');
};
</script>