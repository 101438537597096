<template>
    <svg
        v-if="direction == 'left'"
        v-bind="$attrs"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 19 6"
        class="icon-svg icon-arrow-custom"
        :class="[color ? color : '', size ? 'icon--size-' + size : '', direction ? 'icon--direction-' + direction : '']"
    >
        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.60527 0L0.702032 5.00041C0.512056 5.16133 0.448988 5.41266 0.543133 5.63362C0.637278 5.85458 0.869389 6 1.12793 6H18.5V4.8476H2.73389L7.45707 0.846785L6.60527 0Z"/>
    </svg>
    <svg
        v-else-if="direction == 'right'"
        v-bind="$attrs"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 19 6"
        class="icon-svg icon-arrow-custom"
        :class="[color ? color : '', size ? 'icon--size-' + size : '', direction ? 'icon--direction-' + direction : '']"
    >
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.3947 0L18.298 5.00041C18.4879 5.16133 18.551 5.41266 18.4569 5.63362C18.3627 5.85458 18.1306 6 17.8721 6H0.5V4.8476H16.2661L11.5429 0.846785L12.3947 0Z"/>
    </svg>
</template>

<script>
export default {
    props: {
        color: { type: String, default: "" },
        size: { type: String, default: "default" },
        direction: { type: String, default: "right" },
    },
    components: {},
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
// Direction
.icon--direction {
    &-left,
    &-right {
        // Size
        &.icon--size {
            &-xx-small {
                width: 12px;
            }
            &-x-small {
                width: 14px;
            }
            &-small {
                width: 16px;
            }
            &-default {
                width: 18px;
            }
            &-large {
                width: 20px;
            }
            &-x-large {
                width: 22px;
            }
            &-xx-large {
                width: 24px;
            }
        }
    }
}
</style>
