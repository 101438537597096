<template>
    <div>
        <tit data-aos="fade-up" data-aos-delay="100" class="tit--lg primary--text line-height-1 font-secondary mb-30px mb-md-40px">
            <a href="tel:16618731">Tel. 1661-8731</a>
        </tit>
        <txt class="white--text">
            <v-row>
                <v-col v-for="(item, index) in process" :key="index" data-aos="fade-up" :data-aos-delay="100*(index+1)" cols="auto">
                    <v-row align="center" class="row--xs">
                        <v-col cols="auto"><span class="num txt--sm">{{index+1}}번</span></v-col>
                        <v-col cols="auto"><strong>{{item.title}}</strong></v-col>
                    </v-row>
                </v-col>
            </v-row>
            <div class="mt-20px mt-md-28px">      
                <v-row>
                    <v-col v-for="(item, index) in tel" :key="item.title" data-aos="fade-up" :data-aos-delay="100*(index+1)" cols="12" sm="6" md="12" lg="6">
                        <card-primary :href="'tel:'+item.tel" target="_blank">
                            <v-row align="center" class="row--xs">
                                <v-col cols="auto">{{item.title}}</v-col>
                                <v-col cols="auto"><strong>{{item.tel}}</strong></v-col>
                            </v-row>
                        </card-primary>
                    </v-col>
                </v-row>
            </div>
        </txt>
    </div>
</template>

<script>
import CardPrimary from "@/components/publish/parents/cards/card-primary.vue";
import Tit from "@/components/publish/styles/typography/tit.vue";
import Txt from "@/components/publish/styles/typography/txt.vue";
export default {
    components: {
        CardPrimary,
        Tit,
        Txt,
    },
    data() {
        return { 
            process : [
                {
                    title : "법률상담 및 사건의뢰 문의",
                },
                {
                    title : "등기진행상황 문의",
                },
                {
                    title : "진행사건 문의",
                },
                {
                    title : "상담원 연결",
                },
            ],
            tel : [
                {
                    title : "서울주사무소 (건설)",
                    tel : "02-537-1041",
                },
                {
                    title : "인천분사무소",
                    tel : "032-873-9290",
                },
                {
                    title : "서울분사무소 (이혼)",
                    tel : "02-6952-9291",
                },
                {
                    title : "광주분사무소",
                    tel : "062-229-1727",
                },
            ],
        };
    },
}
</script>

<style lang="scss" scoped>
.num{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    min-width: 36px;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, .2);
}
@media (min-width:576px){
}
@media (min-width:768px){
    .num{
        min-width: 40px;
        width: 40px;
        height: 40px;
    }
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}

</style>