var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', _vm._b({
    staticClass: "card-border-point",
    attrs: {
      "outlined": "",
      "rounded": "xs",
      "color": "rgba(255, 255, 255, .2)"
    }
  }, 'v-card', _vm.$attrs, false), [_c('div', {
    staticClass: "px-16px px-md-24px py-12px py-md-16px"
  }, [_vm._t("default")], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }