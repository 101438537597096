<template>
    <footer class="footer">
        <div class="footer__inner pt-60px pt-md-90px pt-lg-120px pb-40px pb-md-60px pb-lg-80px">
            <v-container>
                <tit class="white--text mb-60px mb-md-90px mb-lg-120px">
                    마음까지 살필 수 있는 <br>
                    든든한 동반자가 되어드리겠습니다.
                </tit>
                <v-row class="my-n2px my-md-n8px" style="max-width: 1300px">
                    <v-col v-for="item in info" :key="item.title" cols="auto" class="py-2px py-md-8px">
                        <txt class="txt--xs txt--light">
                            <span class="white--text">{{item.title}}</span> {{item.info}}
                        </txt>
                    </v-col>
                </v-row>
                <v-divider class="my-40px my-md-60px my-lg-80px" style="border-color: rgba(255, 255, 255, 0.2)" />
                <v-row>
                    <v-col cols="auto" order-sm="2">
                        <v-img src="/images/logo-light.svg" contain class="w-140px w-md-180px" />
                    </v-col>
                    <v-col cols="12" sm="" order-sm="1">
                        <txt class="txt--xs txt--light">Copyright 2023. TAESUNG All Rights Reserved.</txt>
                    </v-col>
                </v-row>
            </v-container>
        </div>
    </footer>
</template>

<script>
import Tit from "@/components/publish/styles/typography/tit.vue";
import Txt from "@/components/publish/styles/typography/txt.vue";

export default {
    components: {
        Tit,
        Txt,
    },
    data() {
        return {
            info : [
                { title : "서울주사무소", info : "서울 서초구 강남대로 202, 모산빌딩 3층", },
                { title : "서울분사무소", info : "서울 서초구 강남대로 196, 구민빌딩 2층", },
                { title : "인천분사무소", info : "인천 미추홀구 경원대로 892, 대동빌딩 1층, 5층", },
                { title : "광주분사무소", info : "광주 동구 지산로 70, 동산빌딩 2층", },
                { title : "대표자명", info : "윤영환, 최유나", },
                { title : "사업자등록번호", info : "248-87-00980", },
                { title : "대표전화", info : "1661-8731", },
                { title : "이메일", info : "tslawfirm@daum.net", },
            ],
        };
    },
};
</script>

<style lang="scss" scoped>
.footer {
    background-color: var(--v-grey-darken4);
    &__inner{
        background-image: url(/images/common/footer-bg.jpg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
}
@media (min-width:1200px){
    .footer {
        &__inner{
            padding-right: var(--header-width);
        }
    }
}

</style>
