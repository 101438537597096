<template>
    <v-btn v-bind="{ color, ...$attrs }" text class="v-btn--text-fit font-weight-bold font-secondary line-height-1" @click="$emit('click')">
        <span v-for="(item, slotName, index) in $slots" :key="index">
            <slot :name="slotName" />
            <icon-arrow-primary class="ml-6px ml-md-8px"/>
        </span>
    </v-btn>
</template>

<script>
import IconArrowPrimary from "@/components/publish/parents/icons/icon-arrow-primary.vue";
export default {
    props: {
        color: { type: String, default: "primary" },
    },
    components: {
        IconArrowPrimary,
    },
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
