<template>
    <u-card-border-point v-bind="$attrs">
        <txt class="white--text">
            <template v-for="(_, slotName) in $slots" #[slotName]>
                <slot :name="slotName" />
            </template>
        </txt> 
    </u-card-border-point>
</template>

<script>
import UCardBorderPoint from "@/components/publish/styles/cards/u-card-border-point.vue";
import Txt from "@/components/publish/styles/typography/txt.vue";

export default {
    props: {},
    components: {
        UCardBorderPoint,
        Txt,
    },
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
