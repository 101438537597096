var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    staticClass: "app--main"
  }, [_c('main-visual'), _c('main-gate'), _c('main-section', {
    staticClass: "main-section--introduction"
  }, [_c('main-introduction')], 1), _c('main-section', {
    staticClass: "main-section--magazine",
    scopedSlots: _vm._u([{
      key: "mainSectionImmersive",
      fn: function () {
        return [_c('v-img', {
          staticClass: "magazine-bg magazine-bg--1 w-100",
          attrs: {
            "src": "/images/main/magazine-bg2.svg",
            "aspect-ratio": "1840 / 480"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('tit-wrap-primary', {
    attrs: {
      "dark": ""
    },
    scopedSlots: _vm._u([{
      key: "TitAdd",
      fn: function () {
        return [_vm._v("Magazine")];
      },
      proxy: true
    }])
  }, [_vm._v(" 매거진 ")])], 1), _c('v-col', {
    staticClass: "mt-n20px mt-md-0",
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('main-magazine')], 1)], 1)], 1), _c('main-section', {
    staticClass: "main-section--telinfo"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('tit-wrap-primary', {
    attrs: {
      "dark": ""
    },
    scopedSlots: _vm._u([{
      key: "TitAdd",
      fn: function () {
        return [_vm._v("Contact Us")];
      },
      proxy: true
    }])
  }, [_vm._v(" 태성 대표번호 ")])], 1), _c('v-col', {
    staticClass: "mt-n20px mt-md-0",
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('div', {
    staticClass: "pl-md-16px pl-lg-32px"
  }, [_c('main-telinfo')], 1)])], 1)], 1), _c('main-section', {
    staticClass: "main-section--contact"
  }, [_c('tit-wrap-primary', {
    staticClass: "text-center",
    attrs: {
      "dark": ""
    },
    scopedSlots: _vm._u([{
      key: "TitAdd",
      fn: function () {
        return [_vm._v("Contact Us")];
      },
      proxy: true
    }])
  }, [_vm._v(" 오시는 길 ")]), _c('main-contact')], 1), _c('popup-layer')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }