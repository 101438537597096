var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm._m(0);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "scroll-ani"
  }, [_c('p', {
    staticClass: "white--text font-size-12 line-height-1 vertical-rl font-secondary mb-10px mb-md-20px"
  }, [_vm._v("Scroll down")]), _c('div', {
    staticClass: "scroll-ani__dot"
  }, [_c('span', {
    staticClass: "scroll-ani__dot__dot"
  })])]);

}]

export { render, staticRenderFns }