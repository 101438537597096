<template>
    <u-tit-wrap-default v-bind="$attrs" :class="titFit ? 'tit-fit' : ''">
        <u-tit-subtit data-aos="fade-up" data-aos-delay="200" v-if="this.$slots['TitAdd']"><slot name="TitAdd" /></u-tit-subtit>
        <u-tit-default data-aos="fade-up" class="tit--xl" :class="dark ? 'white--text' : ''"><slot /></u-tit-default>
        <!-- <u-txt-default v-if="this.$slots['TxtAdd']" class="txt--sm"><slot name="TxtAdd" /></u-txt-default> -->
    </u-tit-wrap-default>
</template>

<script>
import UTitWrapDefault from "@/components/publish/styles/typography/u-tit-wrap-default.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTitSubtit from "@/components/publish/styles/typography/u-tit-subtit.vue";
// import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";

export default {
    props: {
        dark: { type: Boolean, default: false },
        titFit: { type: Boolean, default: false },
    },
    components: {
        UTitWrapDefault,
        UTitDefault,
        UTitSubtit,
        // UTxtDefault,
    },
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.tit-fit{
    .tit{
        position: relative;
        width: fit-content;
    }
}
</style>
