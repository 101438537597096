var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('tit', {
    staticClass: "tit--lg primary--text line-height-1 font-secondary mb-30px mb-md-40px",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "100"
    }
  }, [_c('a', {
    attrs: {
      "href": "tel:16618731"
    }
  }, [_vm._v("Tel. 1661-8731")])]), _c('txt', {
    staticClass: "white--text"
  }, [_c('v-row', _vm._l(_vm.process, function (item, index) {
    return _c('v-col', {
      key: index,
      attrs: {
        "data-aos": "fade-up",
        "data-aos-delay": 100 * (index + 1),
        "cols": "auto"
      }
    }, [_c('v-row', {
      staticClass: "row--xs",
      attrs: {
        "align": "center"
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('span', {
      staticClass: "num txt--sm"
    }, [_vm._v(_vm._s(index + 1) + "번")])]), _c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('strong', [_vm._v(_vm._s(item.title))])])], 1)], 1);
  }), 1), _c('div', {
    staticClass: "mt-20px mt-md-28px"
  }, [_c('v-row', _vm._l(_vm.tel, function (item, index) {
    return _c('v-col', {
      key: item.title,
      attrs: {
        "data-aos": "fade-up",
        "data-aos-delay": 100 * (index + 1),
        "cols": "12",
        "sm": "6",
        "md": "12",
        "lg": "6"
      }
    }, [_c('card-primary', {
      attrs: {
        "href": 'tel:' + item.tel,
        "target": "_blank"
      }
    }, [_c('v-row', {
      staticClass: "row--xs",
      attrs: {
        "align": "center"
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_vm._v(_vm._s(item.title))]), _c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('strong', [_vm._v(_vm._s(item.tel))])])], 1)], 1)], 1);
  }), 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }