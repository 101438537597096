<template>
    <v-row no-gutters>
        <v-col v-for="(item, index) in gate" :key="item.title" cols="12" md="4">
            <v-card :href="item.link" target="_blank" tile class="main-gate v-card--none-active-bg" :style="'background-image: url(' + item.image + ')'">
                <div class="main-gate__inner py-50px px-20px px-md-30px pa-lg-60px">
                    <div class="main-gate__wrap">
                        <div data-aos="fade-up" :data-aos-delay="200 * index" class="text-center">
                            <v-img :src="item.icon" max-width="80" :aspect-ratio="1 / 1" class="mb-20px mb-md-40px mx-auto" />
                            <tit class="font-size-xl-56 white--text">{{ item.title }}</tit>
                            <txt class="txt--lg font-size-xl-28 grey--text text--lighten-3 line-height-17 mt-18px mt-md-24px">
                                <p v-for="detail in item.info" :key="detail" class="mt-0">{{ detail }}</p>
                            </txt>
                            <div class="btn-wrap btn-wrap--lg">
                                <v-btn class="main-gate__btn v-size--xx-large">
                                    바로가기
                                    <icon-arrow-primary class="icon--size-large ml-6px ml-lg-10px" />
                                </v-btn>
                            </div>
                        </div>
                        <!-- <btn-primary2 class="main-gate__btn v-btn--none-active-bg mt-6px mt-md-26px">View More</btn-primary2> -->
                    </div>
                    <p class="main-gate__caption mt-30px mt-md-0">Lawfirm Taesng</p>
                </div>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import BtnPrimary2 from "@/components/publish/parents/buttons/btn-primary2.vue";
import Tit from "@/components/publish/styles/typography/tit.vue";
import Txt from "@/components/publish/styles/typography/txt.vue";
import IconArrowPrimary from "@/components/publish/parents/icons/icon-arrow-primary.vue";

export default {
    components: {
        BtnPrimary2,
        Tit,
        Txt,
        IconArrowPrimary,
    },
    data() {
        return {
            gate: [
                {
                    title: "건설분쟁센터",
                    link: "https://lawtaesung.com/",
                    icon: "/images/main/gate-icon.svg",
                    image: "/images/main/gate-bg.jpg",
                    info: ["아파트하자소송", "정비사업법률자문", "입주예정자협의회법률지원", "건설감정"],
                },
                {
                    title: "이혼가사센터",
                    link: "https://lawyer313.com/",
                    icon: "/images/main/gate-icon2.svg",
                    image: "/images/main/gate-bg2.jpg",
                    info: ["협의이혼", "이혼소송", "양육권&친권", "재산분할"],
                },
                {
                    title: "등기관리시스템",
                    link: "http://www.eregister.co.kr/",
                    icon: "/images/main/gate-icon3.svg",
                    image: "/images/main/gate-bg3.jpg",
                    info: ["아파트 입주등기", "아파트 단체등기", "등기진행상황 조회"],
                },
            ],
        };
    },
};
</script>

<style lang="scss" scoped>
.main-gate {
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    &__caption {
        font-size: 1.2rem;
        line-height: 1;
        letter-spacing: 0.5em !important;
        font-family: var(--font-secondary);
        text-transform: uppercase;
        color: var(--v-grey-lighten1);
    }
    &__btn {
        background-color: #d69c61 !important;
        color: #fff;
        font-size: var(--tit-font-size-sm) !important;
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .main-gate {
        height: 100vh;
        &__inner {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
        }
        &__caption {
            writing-mode: vertical-rl;
            width: fit-content;
        }
    }
}
@media (min-width: 1024px) {
    .main-gate {
        position: relative;
        &::after {
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-image: url(/images/symbol-light.svg);
            background-position: center bottom;
            background-repeat: no-repeat;
            background-size: 100% auto;
            opacity: 0;
            visibility: hidden;
            transition: 0.2s ease-out;
        }
        &__inner {
            background-color: transparent;
            transition: 0.2s ease-out;
        }
        &__wrap {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        &__btn {
            width: 240px;
            height: 68px !important;
        }
        &__caption {
            transition: 0.2s ease-out;
        }
    }
    .main-gate:hover {
        &::after {
            opacity: 1;
            visibility: visible;
        }
        .main-gate__inner {
            background-color: rgba(214, 156, 97, 0.7);
        }
        .main-gate__btn {
            background-color: #fff !important;
            color: #d69c61;
            position: relative;
            z-index: 999;
        }
        .main-gate__caption {
            color: #fff;
        }
    }
}
@media (min-width: 1200px) {
}
</style>