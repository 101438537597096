import axios from "@/plugins/axios";

let url = "/api/console/practices";

export default {
    get(data){
        return axios.get(url, data).then(result => result.data);
    },
    put(data){
        return axios.put(url, data).then(result => result.data);
    }
}
