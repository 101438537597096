<template>
    <swiper data-aos="fade-up" data-aos-delay="100" class="swiper swiper--magazine magazine-slide" ref="swiperMagazine" :options="swiperOptionsMagazine">

        <!-- S: 매거진 -->
        <swiper-slide v-for="board in boards" :key="board._id" >
            <v-card rounded="sm" class="magazine-card transparent" :href="board.url" target="_blank">
                <v-responsive max-width="446" :aspect-ratio="1 / 1" class="image-card" :style="`background-image: url(${board?.thumb})`"></v-responsive>
                <div class="magazine-card__contents pa-16px pa-md-20px pa-lg-40px pb-2px pb-md-6px pb-lg-26px">
                    <div class="w-100">
                        <tit class="white--text tit--sm text-truncate">{{ board?.subject }}</tit>
                        <txt class="white--text text-truncate mt-4px mt-md-8px">{{ board?.summary }}</txt>
                        <btn-primary class="v-btn--none-active-bg mt-md-16px"><span class="v-btn__text">View More</span></btn-primary>
                    </div>
                </div>
            </v-card>
        </swiper-slide>
        <!-- E: 매거진 -->
        
    </swiper>
</template>

<script>
import api from "@/api";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import BtnPrimary from "@/components/publish/parents/buttons/btn-primary.vue";
import Tit from "@/components/publish/styles/typography/tit.vue";
import Txt from "@/components/publish/styles/typography/txt.vue";
export default {
    components: {
        Swiper,
        SwiperSlide,
        BtnPrimary,
        Tit,
        Txt,
    },
    async mounted() {
        await this.init();
    },
    data: () => {
        return {
            boards: [],
            swiperOptionsMagazine: {
                allowTouchMove: true,
                slidesPerView: 2,
                slidesPerColumn: 2,
                slidesPerColumnFill: "row",
                spaceBetween: 10,
                speed: 500,
                centeredSlides: false,
                loop: false,
                breakpoints: {
                    768: {
                        slidesPerView: 2,
                        spaceBetween: 40,
                    },
                    1024: {
                        slidesPerView: 2,
                        spaceBetween: 60,
                    },
                    1544: {
                        slidesPerView: 3,
                        spaceBetween: 60,
                    },
                },
            },
        }
    },
    methods: {
        async init() {
            await this.search();
        },
        async search() {
            let { boards } = await api.v1.boards.gets({})
            this.boards = boards;
        }
    }
}
</script>

<style lang="scss" scoped>
.magazine-card{
    overflow: hidden;
    &__contents{
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1;
        top: 0;
        left: 0;
        background-image: linear-gradient(to top, rgba(0, 0, 0, .8), transparent);
        display: flex;
        align-items: flex-end;
    }
}
@media (min-width:1544px){
    .magazine-slide.swiper-container{
        width: 1458px;
    }
}
</style>