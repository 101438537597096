<template>
    <tit-wrap v-bind="$attrs" class="tit-wrap--lg">
        <slot />
    </tit-wrap>
</template>

<script>
import TitWrap from "@/components/publish/styles/typography/tit-wrap.vue";

export default {
    props: {},
    components: {
        TitWrap,
    },
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
